export const environment = {
    production: true,
    country: "co",
    api_siemprepago: "https://api.siemprepago.com/v1/Scripts/PWCheckout.js?key=leSdXpgb-cBPr8kE832Me2_I56ONV7HQ",
    API_URL: "https://fl-gen-apimgmt-prd.azure-api.net/",
    startPageUrl: "http://grupoemi.com/",
    ig_payment: "../assets/js/ig-payment.js",
    baseStyles: "../assets/css/styles-co.css",
    suscriptionKey: "ca117dedeed244018625e51fc6be426d",
    ambiente: "CoPlsCap",
    RecaptKey: "6LdfRmkUAAAAAPwpxlI4t62x5eBCCi46RK7_4dZ6",
    recaptchaDisable: false,
    imagesRoute: "../../../assets/co/images/",
    alertImage: "../../../assets/images/Alert.png",
    checkImage: "../../../assets/images/check.png",
    warningImage: "../../assets/images/warning.png",
    nombre: "emi",
    codePassport: "4",
    lengthCell: "10",
    regexStartCellPhoneNumber: "^3",
    startNumberCell: "3",
    regularExpressionDocument: "^[1-9][0-9]{4,15}$",
    lengthPhone: "10",
    regexStartPhoneNumber: "^60",
    startNumberPhone: "60",
    countryName: "Colombia",
    lengthPassword: 8,
    adultAge: 18,
    paymentMethodDebitInvoiceCode: "OF03",
    cityName: "ciudad",
    mapCode: "EMI",
    softwareVersionCode: "Version Web",
    version: '8.5',
    ambient: '',
    apiKeyMaps: "AIzaSyCjLDebVboXGKpEa4C9E3i00yCgZo5yv9g",
    title: "Portal Clientes emi",
    gtmURL: "https://www.googletagmanager.com/ns.html?id=GTM-K7T9QS4",
    gtmConfig: "GTM-K7T9QS4",
    shopOnline: "https://shop.grupoemi.com/",
    videoCallRecallTime: 30,
    browserVersionAvailable: {
        chrome: ">63",
        edge: ">13",
        firefox: ">57",
        safari: ">11",
        opera: ">=22",
        mobile: {
            androidBrowser: ">3.10"
        }
    },
    inactivityTime: 15,
    videoCallChangeStatusAvailable: true,
    useSentry: true
}