export enum ButtonType {
    Primary,
    Secundary,
    Tertiary
}

export enum ColorType {
    Primary,
    Secundary,
    Tertiary 
}