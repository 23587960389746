import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  title = 'falck';

  constructor(translate: TranslateService) {
    if (environment.country == 'fk') translate.setDefaultLang('uy');
    translate.setDefaultLang(environment.country);
    translate.use(environment.country);
  }
}
