import { ButtonType } from "../enums/enumerations";

export class ButtonConfiguration {
  buttonType: ButtonType;
  width?: string;
  imageName?: string;
  text: string;
  lineHeight = "21px";
  height?: string;
}
