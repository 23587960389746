import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators'
import * as Sentry from '@sentry/angular';
import { VerifyCode } from 'src/app/public/models/interfaces/verify-code-activation';
import { ActivateUserService } from 'src/app/public/services/activate-user.service';

@Injectable({
  providedIn: 'root'
})
export class ActivatedUserGuard implements CanActivate {
  user: any;
  code: any;
  action: any;
  private correct: boolean;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private activateUserService: ActivateUserService
  ) {
  }

  setLocalStorangeData(valid: any) {
    localStorage.setItem("ActiveteUserStatusCode", valid.StatusCode);
    localStorage.setItem("ActiveteUserMessage", valid.Message);
    localStorage.setItem("ActiveteUserTitle", valid.Title);
  }

  handleFailedRequest(error: any) {
    Sentry.captureException(error);
    this.router.navigate(['/welcome/ini-sesion']);
    return of(false);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.user = route.queryParamMap.get('user')
    this.code = route.queryParamMap.get('code')
    this.action = route.queryParamMap.get('action')
    var verifyCode = new VerifyCode();
    verifyCode.user = this.user;
    verifyCode.code = this.code;
    if (verifyCode.user && verifyCode.code) {
      route.queryParamMap.keys
      if (this.action === "1") {
        return this.activateUserService.activateUser(verifyCode)
          .pipe(map((e: any) => {
            this.setLocalStorangeData(e);
            return true;
          }), catchError((error) => {
            return this.handleFailedRequest(error);
          }))
      } else {
        return this.activateUserService.revertSpecialistRegistration(verifyCode)
          .pipe(map((e: any) => {
            this.setLocalStorangeData(e);
            return true;
          }), catchError((error) => {
            return this.handleFailedRequest(error);
          }));
      }
    }
    return false
  }

}

