import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivatedUserGuard } from './public/sections/login/UI/components/activated-user/activated-user-guard.guard';
import { ActivatedUserComponent } from './public/sections/login/UI/components/activated-user/activated-user.component';

const routes: Routes = [
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },
  {
    path: 'ActivateUser',
    canActivate: [ActivatedUserGuard],
    component: ActivatedUserComponent
  },
  { path: 'welcome', loadChildren: () => import('./public/public.module').then(x => x.PublicModule) },
  { path: 'portal', loadChildren: () => import('./portal/portal.module').then(x => x.PortalModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
