import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { InformativeModalComponent } from 'src/app/shared/components/informative-modal/informative-modal.component';

@Component({
  selector: 'app-activated-user',
  templateUrl: './activated-user.component.html',
  styleUrls: ['./activated-user.component.scss']
})
export class ActivatedUserComponent implements OnInit {
  bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService, private router: Router) {
  }

  ngOnInit(): void {
    let profile: any = this.getParam('profile') ? this.getParam('profile') : 'customers';
    localStorage.setItem("profile", profile);
    let ActiveteUserStatusCode = localStorage.getItem("ActiveteUserStatusCode");
    let ActiveteUserMessage = localStorage.getItem("ActiveteUserMessage");
    let ActiveteUserTitle = localStorage.getItem("ActiveteUserTitle");

    const initialState: ModalOptions = {
      initialState: {
        iconHeader: ActiveteUserStatusCode === "0" ? 'alert-circle.svg' : 'alert-check.svg',
        subTitle: ActiveteUserTitle,
        description: ActiveteUserMessage,
        onlyButtonConfirm: true,
        textButton: 'CONTINUAR',
        textButton1: '',
        textButton2: '',
        visibleButtonClose: false,
      },
      class: 'modal-dialog-centered modal-rounded',
      id: 'modal-activation',
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(InformativeModalComponent, initialState);
    this.bsModalRef.content.onClose.subscribe((resolved: boolean) => {
      if (resolved) {
        this.router.navigate(['/welcome/ini-sesion']);
      }
    })
  }

  getParam(param: any) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] == param) {
        return sParameterName[1];
      }
    }
    return sParameterName;
  }

}
