<div class="modal-dialog modal-md modal-center-align">
    <div class="modal-body modal-check col-md-12">
        <div class="row">
            <div class="col-md-12">
                <div class="contenedor">
                    <div class="icon-close cursor-pointer">
                        <a class="cancel" (click)="closeModal()" *ngIf="visibleButtonClose">
                            <img src="{{imagesRoute}}/icons/cancel.svg" alt="Imagen">
                        </a>
                    </div>

                    <div class="icon-header">
                        <img src="{{imagesRoute}}/icons/{{iconHeader}}" alt="icon-header" />
                    </div>

                    <div class="subTitle">
                        <span>{{subTitle}}</span>
                    </div>

                    <div class="description row" *ngIf="description != ''">
                        <span class="col-md-12" [innerHTML]="description"></span>
                    </div>

                    <div class="buttons">
                        <div class="only-button" *ngIf="onlyButtonConfirm">
                            <app-button id="focusBtn" [configuration]="configurationButton" (onClick)="confirm()"></app-button>
                        </div>
                        <div class="row" *ngIf="!onlyButtonConfirm">
                            <app-button class="col-sm-6 text-center mb-2" [configuration]="configurationButton1" (onClick)="cancel()"></app-button>
                            <app-button class="col-sm-6 text-center mb-3" [configuration]="configurationButton2" (onClick)="confirm()"></app-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
