import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { ButtonType } from '../../enums/enumerations';
import { ButtonConfiguration } from '../../models/button-configuration';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {  
  style: any;
  styleWithInImg: any;
  colorClassName: any;
  widthButton: string;
  urlImage: string;
  
  @Input() configuration: ButtonConfiguration;
  @Input() disabled: boolean;
  @Output() onClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    this.urlImage = `${environment.imagesRoute}icons/${this.configuration.imageName}`;
    this.setStyle();
  }

  setStyle() {
    this.configuration.width ? this.widthButton = this.configuration.width : this.widthButton = "185px";

    switch (this.configuration.buttonType) {
      case ButtonType.Primary:
        this.colorClassName = 'color-button-primary';
        break;
      case ButtonType.Secundary:
        this.colorClassName = 'color-button-secondary';
        break;
      case ButtonType.Tertiary:
        this.colorClassName = 'color-button-ternary';
        break;
    }

    let _height = (!this.configuration.height) ? "51px" :  this.configuration.height ;

    this.style = {
      width: this.widthButton,
      height: _height,
      border: '1px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '18px',
      borderRadius: '30px',
      fontWeight: '500',
      text: this.configuration.text,
      lineHeight: this.configuration.lineHeight
    }
    this.styleWithInImg = {
      width: this.widthButton,
      height: _height,
      border: '1px',
      alignItems: 'center',
      fontSize: '18px',
      borderRadius: '30px',
      fontWeight: '500',
      text: this.configuration.text,
      lineHeight: this.configuration.lineHeight
    }
  }

  onClickButton(event: any) {
    this.onClick.emit(event);
  }

}
