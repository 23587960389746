<div *ngIf="configuration">
    <ng-container *ngTemplateOutlet="configuration.imageName ?  withImage : withoutImage">
    </ng-container>

    <ng-template #withImage>
        <button [ngStyle]="style" [ngClass]="colorClassName" [disabled]="disabled"
            (click)="onClickButton($event)" class="not-hover">
            <div>
              <img class="icon-btn" src="{{urlImage}}" alt="icon-user" width="72px"/>
            </div>
            <div class="text-btn">
              {{configuration.text}}
            </div>
        </button>
    </ng-template>

    <ng-template #withoutImage>
        <button [ngStyle]="styleWithInImg" [ngClass]="colorClassName" [disabled]="disabled"
            (click)="onClickButton($event)">{{configuration.text}}</button>
    </ng-template>
</div>
